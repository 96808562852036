/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { CommonStyles } from '../../../../common/commonStyles.ts';
import { Button, IInnerButtonProps } from '../../../Button/Button.tsx';
import { Icons } from '../../../Icon/Icons.tsx';
import { SelectionListClickEventHandler } from '../../../SelectionList/types.ts';
import { Text } from '../../../Text/Text.tsx';
import * as themes from '../../themes.ts';
import { IToolButton, ToolButtonClickEventHandler } from '../../types.ts';
import { DropdownPopup } from '../DropdownPopup.tsx';

export interface IToolButtonProps {
  tool: IToolButton;
  theme?: 'LIGHT' | 'DARK';
  isMenuVisible?: boolean;
  borderRadius?: number | string;
  onMouseDown?: ToolButtonClickEventHandler<IToolButton>;
  onDropdownHide?: () => void;
  onDropdownClick?: SelectionListClickEventHandler;
  hasHoverBorder?: boolean;
}

/**
 * A single ToolButtonSet button.
 */
export const ToolButton: React.FC<IToolButtonProps> = ({
  isMenuVisible = false,
  borderRadius,
  onDropdownHide,
  onDropdownClick,
  theme = 'DARK',
  hasHoverBorder = true,
  tool,
}) => (
  <Button
    dataTest={tool.dataTest}
    isEnabled={tool.isEnabled}
    onClick={clickHandler(tool)}
    tooltip={tool.tooltip}
  >
    <Inner
      tool={tool}
      theme={theme}
      isMenuVisible={isMenuVisible}
      borderRadius={borderRadius}
      onDropdownHide={onDropdownHide}
      onDropdownClick={onDropdownClick}
      hasHoverBorder={hasHoverBorder}
    />
  </Button>
);

function clickHandler(tool: IToolButton) {
  return () => tool.onClick?.({ id: tool.id, tool });
}

export interface IInnerProps {
  tool: IToolButton;
  theme: 'LIGHT' | 'DARK';
  isMenuVisible: boolean;
  borderRadius?: number | string;
  onDropdownHide?: () => void;
  onDropdownClick?: SelectionListClickEventHandler;
  hasHoverBorder: boolean;
}
const Inner: React.FC<IInnerProps & IInnerButtonProps> = ({
  isEnabled,
  isOver,
  tool,
  theme,
  isMenuVisible,
  borderRadius,
  onDropdownHide,
  onDropdownClick,
  hasHoverBorder,
}) => {
  const { label, icon, isSelected = false, dropdown } = tool;

  const cursor = isEnabled && !isSelected ? 'pointer' : 'default';
  const hasIcon = Boolean(icon);
  const hasLabel = Boolean(label);
  const hasMenu = Boolean(dropdown);
  const hasDropdownIcon = hasMenu && hasLabel;

  const iconTheme = themes.icon(theme);
  const labelTheme = themes.label(theme);
  const toolBackgroundTheme = themes.toolBackground(theme);

  const textColor = isEnabled
    ? isOver && !isSelected
      ? labelTheme.OVER
      : isSelected
        ? labelTheme.SELECTED
        : labelTheme.DEFAULT
    : labelTheme.DISABLED;

  const iconColor = isEnabled
    ? isOver && !isSelected
      ? iconTheme.OVER
      : isSelected
        ? iconTheme.SELECTED
        : iconTheme.DEFAULT
    : iconTheme.DISABLED;

  const styles = {
    content: css({
      cursor,
      borderRadius,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      height: 32,
      padding: '4px 8px',
      border: hasHoverBorder ? `1px solid ${color.format(0)}` : undefined,
      background: isSelected ? toolBackgroundTheme.SELECTED : undefined,
      ':hover': {
        background: !isSelected ? toolBackgroundTheme.OVER : undefined,
        border: hasHoverBorder ? `1px solid ${color.format(-0.04)}` : undefined,
      },
      ...CommonStyles.MaterialCubicTransitions,
    }),
    icon: css({
      width: 24,
      height: 24,
    }),
    label: css({
      marginLeft: hasIcon ? 8 : 15,
      marginRight: hasMenu ? 0 : hasIcon ? 8 : 15,
      color: textColor,
      ...CommonStyles.MaterialCubicTransitions,
    }),
    dropdownIcon: css({
      position: 'relative',
      marginRight: hasIcon ? 0 : 5,
      marginLeft: -3,
      marginBottom: -1,
    }),
  };

  const elIcon = icon && (
    <div css={styles.icon}>{icon({ size: 24, fill: iconColor })}</div>
  );

  const elLabel = label && (
    <Text cursor={cursor} selectable={false} style={styles.label}>
      {label}
    </Text>
  );

  const elDropdown = dropdown && isMenuVisible && (
    <DropdownPopup
      {...dropdown}
      onHide={onDropdownHide}
      onClick={onDropdownClick}
    />
  );

  const elDropdownIcon = hasDropdownIcon && (
    <div css={styles.dropdownIcon}>
      <Icons.arrowDropDown fill={iconColor} />
    </div>
  );

  return (
    <div>
      <div css={styles.content}>
        {elIcon}
        {elLabel}
        {elDropdownIcon}
      </div>
      {elDropdown}
    </div>
  );
};
