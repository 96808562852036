/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  ToolButtonSet,
  IToolButton,
  ToolButtonClickEvent,
  ToolButtonDropdownClickEvent,
} from '@seeeverything/ui.primitives/src/components/ToolButtonSet/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { css } from '@emotion/react';

export interface IUserStatusProps {
  email: string;
  onUserClick?: () => void;
  onSwitchAccountClick?: () => void;
  onLogoutClick?: () => void;
}

export const UserStatus = ({
  email,
  onLogoutClick,
  onSwitchAccountClick,
  onUserClick,
}: IUserStatusProps) => {
  const handleToolClick = useCallback(
    (e: ToolButtonClickEvent) => {
      if (e.tool.id === 'USER') onUserClick?.();
    },
    [onUserClick],
  );

  const handleDropdownClick = useCallback(
    (e: ToolButtonDropdownClickEvent) => {
      switch (e.itemId) {
        case 'SWITCH_ACCOUNT':
          onSwitchAccountClick?.();
          break;
        case 'LOGOUT':
          onLogoutClick?.();
          break;

        default:
          break;
      }
    },
    [onLogoutClick, onSwitchAccountClick],
  );

  const elUser = (
    <div css={styles.user}>
      <Text color={'#ffffff'} size={14}>
        {email}
      </Text>
      <div css={styles.userIcon}>
        <Icons.tickDone fill={COLORS.WHITE} size={18} />
      </div>
    </div>
  );

  const tools: IToolButton[] = [
    { id: 'USER', label: elUser, icon: Icons.face },
    {
      id: 'OPTIONS',
      icon: Icons.moreVert,
      dropdown: {
        width: 210,
        items: [
          {
            id: 'SWITCH_ACCOUNT',
            icon: Icons.switchAccount,
            content: 'Switch Account',
          },
          { id: 'LOGOUT', icon: Icons.power, content: 'Logout' },
        ],
      },
    },
  ];

  return (
    <div css={styles.base}>
      <ToolButtonSet
        toolButtons={tools}
        theme={'LIGHT'}
        onClick={handleToolClick}
        onDropdownClick={handleDropdownClick}
      />
    </div>
  );
};

const styles = {
  base: css({
    position: 'absolute',
    inset: '15px 20px auto auto',
  }),
  user: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  userIcon: css({
    marginLeft: 3,
    marginBottom: -2,
  }),
};
