/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { value } from '@seeeverything/ui.util/src/value/index.ts';
import React from 'react';
import { DropdownList } from '../../DropdownList/DropdownList.tsx';
import { Popup } from '../../Popup/Popup.tsx';
import {
  ISelectionListItem,
  SelectionListClickEventHandler,
} from '../../SelectionList/types.ts';
import { Spinner } from '../../Spinner/index.ts';

export interface IToolDropdownProps {
  items: ISelectionListItem[] | Promise<ISelectionListItem[]>;
  width?: string | number;
  height?: number;
  isScrollable?: boolean;
  onHide?: () => void;
  onClick?: SelectionListClickEventHandler;
  minWidth?: string | number;
}

export interface IToolDropdownState {
  isLoading?: boolean;
  items?: ISelectionListItem[] | Promise<ISelectionListItem[]>;
}

export class DropdownPopup extends React.Component<
  IToolDropdownProps,
  IToolDropdownState
> {
  public state: IToolDropdownState = {
    isLoading: true,
  };

  private unmounted = false;

  public componentWillUnmount() {
    this.unmounted = true;
  }

  private updateState = (newState: Partial<IToolDropdownState>) => {
    if (!this.unmounted) {
      this.setState(newState);
    }
  };

  public componentDidMount() {
    this.updateState({ items: this.props.items, isLoading: true });
    this.loadData();
  }

  public componentDidUpdate(
    prevProps: IToolDropdownProps,
    prevState: IToolDropdownState,
  ) {
    if (prevProps.items !== this.props.items) {
      this.updateState({ items: this.props.items, isLoading: true });
    }

    if (this.state.items !== prevState.items) {
      this.loadData();
    }
  }

  private async loadData() {
    let { items } = this.state;
    if (items) {
      if (value.isPromise(items)) {
        this.updateState({ isLoading: true });
        items = await items;
      }
      this.updateState({ items, isLoading: false });
    }
  }

  public render() {
    const { onHide, width, height, isScrollable, onClick, minWidth } =
      this.props;
    const { isLoading } = this.state;
    const items = this.state.items as ISelectionListItem[];

    const computedStyles = {
      spinner: css({
        width,
        minWidth,
        height: 300,
        border: '1px solid rgba(0, 0, 0, 0.18)',
        borderRadius: 3,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
        backgroundColor: 'white',
      }),
    };

    const elContent = isLoading ? (
      <div css={computedStyles.spinner}>
        <Spinner center={true} />
      </div>
    ) : (
      <DropdownList
        items={items}
        width={width}
        minWidth={minWidth}
        isScrollable={isScrollable}
        onClick={onClick}
        height={height}
      />
    );

    return <Popup onHide={onHide}>{elContent}</Popup>;
  }
}
