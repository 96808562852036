/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/Image.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useRouter } from 'next/router.js';
import { useCallback, useEffect, useState } from 'react';
import { interval } from 'rxjs';
import { authApi } from '../../api/api.ts';
import { NotAuthorized } from '../../components/NotAuthorized/NotAuthorized.tsx';
import { UserStatus } from '../../components/UserStatus/UserStatus.tsx';

export default function NotAuthorizedPage() {
  const router = useRouter();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState<string>(undefined);

  const setAuthValues = useCallback(async () => {
    try {
      setIsAuthenticated(await authApi.getIsAuthenticated());
      setEmail(await authApi.getEmail());
    } catch {
      setIsAuthenticated(false);
      setEmail(undefined);
    }
  }, []);

  useEffect(() => {
    setAuthValues();
    const subscription = interval(5000).subscribe(setAuthValues);

    return () => subscription.unsubscribe();
  }, [setAuthValues]);

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogin = useCallback(() => {
    router.push('/auth/login');
  }, [router]);

  const handleLogout = useCallback(() => {
    setIsLoggingOut(true);
    authApi.logout();
  }, []);

  const styles = {
    base: css({
      position: 'absolute',
      inset: 0,
      color: COLORS.WHITE,
      backgroundColor: COLORS.BLUE,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    logo: css({
      position: 'absolute',
      inset: '0px auto auto 0px',
    }),
    emailSupport: css({
      position: 'absolute',
      inset: 'auto auto 15px 18px',
    }),
  };

  const elUserStatus = email && (
    <UserStatus
      email={email}
      onSwitchAccountClick={handleLogin}
      onLogoutClick={handleLogout}
    />
  );

  const elNotAuthorized = (
    <NotAuthorized
      isAuthenticated={isAuthenticated}
      onLoginClick={handleLogin}
    />
  );

  const elContent = !isLoggingOut && (
    <div>
      {elNotAuthorized}
      {elUserStatus}
    </div>
  );

  return (
    <div css={styles.base}>
      {elContent}
      <div css={styles.logo}>
        <a href={'/'}>
          <Image
            path={'/server.auth/images/logo/logo-white.png'}
            path2x={'/server.auth/images/logo/logo-white@2x-png'}
            width={81}
            height={77}
          />
        </a>
      </div>

      <div css={styles.emailSupport}>
        <Text size={13} color={'white'}>
          {'Please contact your support team for assistance.'}
        </Text>
      </div>
    </div>
  );
}
