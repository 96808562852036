/** @jsxImportSource @emotion/react */
import * as themes from '../themes.ts';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IDividerProps {
  theme: 'LIGHT' | 'DARK';
  isFirst: boolean;
  isLast: boolean;
  isVisible?: boolean;
  marginX?: number;
}

export const Divider = (props: IDividerProps) => {
  const { isVisible = true, marginX, isFirst, isLast, theme } = props;
  const borderColor = isVisible ? themes.border(theme) : 'transparent';
  const styles = {
    base: css({
      position: 'relative',
      boxSizing: 'border-box',
      width: isVisible ? 1 : 0,
      height: 32,
      borderLeft: isVisible
        ? `solid 1px ${color.format(borderColor)}`
        : undefined,
      overflow: 'hidden',
      marginLeft: !isFirst && !isLast ? marginX : undefined,
      marginRight: !isLast ? marginX : undefined,
    }),
  };
  return <div css={styles.base} />;
};
