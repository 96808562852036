/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { Fragment, memo, useCallback, useState } from 'react';
import { SelectionListClickEvent } from '../SelectionList/types.ts';
import { Divider } from './components/Divider.tsx';
import { ToolButton } from './components/ToolButton/ToolButton.tsx';
import * as themes from './themes.ts';
import {
  IToolButton,
  ToolButtonClickEvent,
  ToolButtonClickEventHandler,
  ToolButtonDropdownClickEventHandler,
} from './types.ts';

export interface IToolButtonSetProps {
  background?: boolean | number | string;
  border?: boolean;
  isEnabled?: boolean;
  onClick?: ToolButtonClickEventHandler<IToolButton>;
  onDropdownClick?: ToolButtonDropdownClickEventHandler;
  theme?: 'LIGHT' | 'DARK';
  toolButtons: IToolButton[];
}

/**
 * A set of toolbar buttons.
 */
const View: React.FC<IToolButtonSetProps> = ({
  background: propsBackground,
  border: propsBorder,
  isEnabled = true,
  onClick,
  onDropdownClick,
  theme = 'DARK',
  toolButtons,
}) => {
  const [showToolMenuId, setShowToolMenuId] = useState<string | number>();
  const background =
    propsBackground === 0
      ? 'transparent'
      : propsBackground !== undefined
        ? propsBackground
        : themes.background(theme);

  const border = propsBorder !== undefined ? propsBorder : themes.border(theme);

  const hasBorder = border !== 'transparent' && border !== false;

  const styles = {
    base: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: color.format(background),
      boxSizing: 'border-box',
      height: 34,
      overflow: 'hidden',
      border: `solid 1px ${color.format(border)}`,
      borderRadius: 3,
    }),
  };

  const clickHandler = useCallback(
    (tool: IToolButton) => (e: ToolButtonClickEvent) => {
      tool.onClick?.(e);
      if (tool.dropdown) setShowToolMenuId(tool.id);
      onClick?.(e);
    },
    [onClick],
  );

  const dropdownHideHandler = useCallback(
    () => setShowToolMenuId(undefined),
    [],
  );

  const dropdownClickHandler = useCallback(
    (tool: IToolButton) => (e: SelectionListClickEvent) => {
      tool.onDropdownClick?.({
        toolId: tool.id,
        itemId: e.id,
      });
      onDropdownClick?.({
        toolId: tool.id,
        itemId: e.id,
      });
      setShowToolMenuId(undefined);
    },
    [onDropdownClick],
  );

  const elItems = toolButtons
    .map((tool, index) => {
      const isFirst = index === 0;
      const isLast = index === toolButtons.length - 1;
      const hasToolDivider = hasBorder || tool.divider || false;
      const isDividerVisible = !isLast && hasToolDivider;
      const borderRadius = hasBorder ? 0 : 3;

      const isMenuVisible = tool.dropdown && showToolMenuId === tool.id;
      const isToolEnabled =
        tool.isEnabled === undefined ? isEnabled : tool.isEnabled;

      tool = {
        ...tool,
        isEnabled: isToolEnabled,
        onClick: clickHandler(tool),
      };

      return (
        <Fragment key={`toolButton-${index}`}>
          <ToolButton
            key={index}
            tool={tool}
            theme={theme}
            isMenuVisible={isMenuVisible}
            borderRadius={borderRadius}
            onDropdownHide={dropdownHideHandler}
            onDropdownClick={dropdownClickHandler(tool)}
            hasHoverBorder={!propsBorder}
          />
          <Divider
            key={`div-${index}`}
            theme={theme}
            isFirst={isFirst}
            isLast={isLast}
            isVisible={isDividerVisible}
            marginX={!hasBorder ? 2 : 0}
          />
        </Fragment>
      );
    })
    .filter(Boolean);

  return <div css={styles.base}>{elItems}</div>;
};

export const ToolButtonSet = memo(View, propsAreEqualFastCompare);
