import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
const NOT_SUPPORTED = 'Theme not supported.';

export function background(theme: 'LIGHT' | 'DARK') {
  switch (theme) {
    case 'DARK':
      return color.format(1);
    case 'LIGHT':
      return color.format(0.1);
    default:
      throw new Error(NOT_SUPPORTED);
  }
}

export function toolBackground(theme: 'LIGHT' | 'DARK') {
  switch (theme) {
    case 'DARK':
      return {
        OVER: color.format(-0.04),
        SELECTED: COLORS.BLUE,
      };

    case 'LIGHT':
      return {
        OVER: color.format(0.1),
        SELECTED: color.format(0.1),
      };

    default:
      throw new Error(NOT_SUPPORTED);
  }
}

export function border(theme: 'LIGHT' | 'DARK') {
  switch (theme) {
    case 'DARK':
      return '#cccccc';
    case 'LIGHT':
      return color.format(0.2);
    default:
      throw new Error(NOT_SUPPORTED);
  }
}

export function icon(theme: 'LIGHT' | 'DARK') {
  switch (theme) {
    case 'DARK':
      return {
        DEFAULT: COLORS.BLUE,
        OVER: COLORS.BLUE,
        SELECTED: color.format(1),
        DISABLED: color.format(-0.1),
      };
    case 'LIGHT':
      return {
        DEFAULT: color.format(0.8),
        OVER: color.format(1),
        SELECTED: color.format(1),
        DISABLED: color.format(0.3),
      };
    default:
      throw new Error(NOT_SUPPORTED);
  }
}

export function label(theme: 'LIGHT' | 'DARK') {
  switch (theme) {
    case 'DARK':
      return {
        DEFAULT: COLORS.BLUE,
        OVER: COLORS.BLUE,
        SELECTED: color.format(1),
        DISABLED: color.format(-0.1),
      };
    case 'LIGHT':
      return {
        DEFAULT: color.format(0.8),
        OVER: color.format(1),
        SELECTED: color.format(1),
        DISABLED: color.format(0.3),
      };
    default:
      throw new Error(NOT_SUPPORTED);
  }
}
